header {
  max-width: 100%;
  padding: 0 20px;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  position: fixed;
  right: 0;
  left: 0;
  top: 0;
  z-index: 99;
}

.logo-trans {
  width: 50px;
  height: 50px;
  display: block;
  border: 5px solid #000;
  position: relative;
  transform: rotate(0deg);
  transition: transform 0.4s ease-in-out;
  flex-shrink: 0;
}

.logo {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(0deg);
  transition: transform 0.4s ease-in-out;
  font-weight: 700;
  font-size: 1.3rem;
  color: #000;
}

.logo-trans:hover {
  opacity: 1;
  transform: rotate(-90deg);
  transition: transform 0.8s ease-in-out;
}

.logo-trans:hover .logo {
  transform: translate(-50%, -50%) rotate(90deg);
  transform-origin: center;
}

nav {
  width: 100%;
  height: 71px;
}

nav ul {
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

nav li {
  display: inline-block;
  margin: 10px;
}

nav li a {
  text-transform: uppercase;
  letter-spacing: 2px;
  color: #fff;
  font-size: 1.1rem;
  font-weight: bold;
  padding: 10px;
  position: relative;
}

nav li a:hover {
  outline: 0;
  cursor: pointer;
  color: #fff;
}

nav li a:hover:after,
nav li a:hover:before {
  outline: 0;
  background-color: #425bb5;
  -webkit-transition: all 0.5s ease 0s;
  -moz-transition: all 0.5s ease 0s;
  -ms-transition: all 0.5s ease 0s;
  -o-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
}

nav li a:after,
nav li a:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  right: 0;
  width: 3px;
  height: 3px;
}

nav li a:after,
nav li a:before {
  -webkit-transition: all 0.5s ease 0s;
  -moz-transition: all 0.5s ease 0s;
  -ms-transition: all 0.5s ease 0s;
  -o-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
}

nav li a:hover:before {
  width: 3px;
  height: 100%;
}

nav li a:hover:after {
  width: 100%;
  height: 3px;
}

.bg-nav nav li a {
  color: #000;
}

.bg-nav {
  background: #fff;
  border-bottom: 1px solid #f3f3f3;
  -webkit-box-shadow: 0 3px 14px -4px #000;
  -moz-box-shadow: 0 3px 14px -4px #000;
  box-shadow: 0 3px 14px -4px #000;
}

.bg-nav .logo-trans {
  border-color: #000;
}

.bg-nav .logo {
  color: #000;
}
