* {
    margin: 0;
    padding: 0;
    border: 0;
    list-style: none;
    text-decoration: none;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
}

a:focus,
a:hover {
    text-decoration: none;
    color: inherit;
}

img {
    width: 100%;
    height: auto;
}

/* custom css classes */

.d-flex {
    display: flex;
}

.flex-wrap {
    flex-wrap: wrap;
}

.align-center {
    align-items: center;
}

.align-end {
    align-items: flex-end;
}

.align-start {
    align-items: flex-start;
}

.justify-center {
    justify-content: center;
}

.justify-content-between {
    justify-content: space-between;
}

.justify-content-around {
    justify-content: space-around;
}

.flex-column {
    flex-direction: column;
}

.flex-row-reverse {
    flex-direction: row-reverse;
}

.text-center {
    text-align: center;
}

.text-right {
    text-align: right;
}


/* General
==================================================*/

html {
    font-size: 62.5%;
    scroll-behavior: smooth;
    height: 100%;
    -webkit-text-size-adjust: 100%;
}

body {
    font-family: 'Open sans', -apple-system, BlinkMacSystemFont, Segoe UI, segoe ui, SegoeUI, Segoe, Helvetica, Arial, sans-serif;
    color: #555555;
    font-weight: 400;
    overflow-x: hidden;
    -webkit-font-smoothing: antialiased;
    height: 100%;
    background-color: #ffffff;
    line-height: 1.5;
}

.section-title {
    font-size: 5rem;
    line-height: 1;
    margin-bottom: 50px;
}

.container {
    max-width: 1220px;
    margin: 0 auto;
    padding: 0 20px;
}

.section {
    padding: 100px 0;
}

footer {
    text-align: right;
    padding: 10px;
    box-shadow: 0 3px 14px -4px #000;
}

.footer-text {
    font-size: 1.6rem;
    color: #000;
}

.mobile-menu {
    display: none;
}

.overlay {
    position: absolute;
    width: 100%;
    height: 80px;
}

.overlay>label {
    cursor: pointer;
    display: block;
    position: relative;
    top: -8px;
    left: 0;
    width: 30px;
    height: 16px;
    border-top: 2px solid #000;
    border-bottom: 2px solid #000;
}

.overlay>label::before {
    content: '';
    display: block;
    position: relative;
    top: 5px;
    border-top: 2px solid #000;
}

.bg-nav .overlay>label,
.bg-nav .overlay>label::before {
    border-color: #000;
}


.verticalNav {
    position: absolute;
    top: -75px;
    left: 0;
    right: 0;
    height: 50px;
    padding: 5px 30px;
    font-size: 1.8rem;
    box-sizing: border-box;
    transition: top 600ms cubic-bezier(0.17, 0.04, 0.03, 0.94);
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.bg-nav .verticalNav {
    background-color: #fff;
}

.verticalNav a {
    color: #000;
    padding-left: 10px;
    text-transform: capitalize;
    font-size: 1.6rem;
}

.bg-nav .verticalNav a {
    color: #000;
}

.verticalNav a:first-child {
    padding-left: 0;
}



#toggle {
    position: absolute;
    visibility: hidden;
}

#toggle:checked~.verticalNav {
    top: 76px;
}

#toggle:checked~.puppies {
    margin-top: 135px;
}

.banner-social {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 50px;
}

.banner-social svg {
    fill: #fff;
}

/* Responsive media queries
==================================================*/

@media only screen and (max-width : 1099px) {
    nav li:first-child a {
        color: #000;
    }
}


@media only screen and (max-width : 899px) {
    nav li:nth-child(2) a {
        color: #000;
    }

    .banner-inner,
    .type-text {
        width: 100%;
    }

    .dash-wrap {
        padding-left: 70px;
    }

    .banner-inner {
        padding-top: 30px;
    }

    .type-text {
        text-align: left;
        height: 75px;
        color: #000;
    }

    .about-wrap,
    .timeline-wrap,
    .contact-details,
    .contact-form-wrap {
        width: 100%;
    }

    .about-img-div {
        width: 400px;
        margin: 20px auto 0;
    }

    .section.banner h1 {
        color: #000;
    }

    .img-div {
        margin: 0 auto;
    }

    .growth-img {
        display: none;
    }

    .section {
        padding: 50px 0;
    }

    .portfolio-inner {
        padding-top: 20px;
    }

    .project-list {
        width: auto;
        max-width: 320px;
    }

    .contact-details {
        margin-bottom: 30px;
    }

    .about-border {
        right: auto;
        left: 20px;
    }

    .section-title,
    .about .title {
        text-align: center;
    }

    .section-title br,
    .about .title br {
        display: none;
    }
}

@media only screen and (max-width : 760px) {
    .banner-social svg {
        fill: #000;
    }
}

@media only screen and (max-width : 599px) {
    .dash-wrap {
        padding-left: 40px;
    }

    .mobile-menu {
        display: block;
        width: 80%;
    }

    .banner-inner {
        padding-top: 50px;
    }

    .logo-wrap {
        width: 20%;
        text-align: right;
    }

    .logo-trans {
        display: inline-block;
    }

    nav {
        flex-wrap: wrap;
        height: auto;
        padding: 10px 0;
    }

    nav ul {
        display: none;
    }

    nav li {
        margin: 5px;
    }

    nav li a {
        padding: 5px;
        display: block;
    }

    .banner-wrap .dash {
        font-size: 2.8rem;
        margin-top: 20px;
    }

    .section.banner h1 {
        font-size: 4.2rem;
        color: #000;
    }

    .type-text {
        font-size: 3rem;
    }

    .about .title,
    .section-title {
        font-size: 3.5rem;
    }

    .about h2 {
        font-size: 2.5rem;
    }

    .abt-me-txt {
        font-size: 1.8rem;
    }

    .signature-wrap {
        width: 100%;
        text-align: center;
        margin-bottom: 20px;
    }

    .about .signature {
        width: 200px;
        margin-top: 10px;
    }

    .about-border {
        top: 20px;
        left: 60px;
    }

    .img-div {
        height: 300px;
        width: 300px;
        font-size: 3rem;
    }

    .about-border {
        height: 300px;
        width: 300px;
        left: 30px;
        top: 10px;
    }

    .banner-social {
        margin-top: 10px;
    }
}

@media only screen and (max-width : 400px) {
    nav li {
        width: 40%;
    }

    nav li a {
        text-align: center;
    }

    .dash-wrap {
        padding-left: 0;
    }

    .about-img-div {
        margin: 0 -10px;
    }

    .about-border {
        top: 0;
        left: 0;
    }
}