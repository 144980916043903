/* projects section
==================================================*/
.project-list {
    width: 30%;
}

.project-list img {
    min-height: 200px;
    object-fit: fill;
}

.portfolio_container {
    margin: 0 -15px;
}

.portfolio .project-list {
    padding: 15px;
}

.portfolio .categories-grid span {
    font-size: 30px;
    margin-bottom: 30px;
    display: inline-block;
}

.portfolio .categories-grid .categories ul li {
    list-style: none;
    margin: 20px 0;
}

.portfolio .categories-grid .categories ul li a {
    display: inline-block;
    color: #60606e;
    padding: 0 10px;
    margin: 0 14px;
    -webkit-transition: all .2s ease-in-out .2s;
    transition: all .2s ease-in-out .2s;
}

.portfolio .categories-grid .categories ul li a:hover,
.portfolio .categories-grid .categories ul li a:focus {
    text-decoration: none;
}

.portfolio .categories-grid .categories ul li a.active {
    background-color: #425BB5;
    padding: 0px 20px;
    color: white;
    text-decoration: none;
}

.portfolio_filter {
    padding-left: 0;
}

.portfolio_item {
    position: relative;
    overflow: hidden;
    display: block;
    margin-bottom: 30px;
}

.portfolio_item .portfolio_item_hover {
    position: absolute;
    top: 0px;
    left: 0px;
    height: 100%;
    width: 100%;
    background-color: #555555;
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
    opacity: 0;
    -webkit-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
    color: #333;
}

.portfolio_item .portfolio_item_hover .item_info {
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    padding: 10px;
    width: 100%;
    font-weight: bold;
}

.portfolio_item .portfolio_item_hover .item_info span {
    display: block;
    color: #fff;
    font-size: 14px;
    -webkit-transform: translateY(-100px);
    transform: translateY(-100px);
    -webkit-transition: all .3s ease-in-out .3s;
    transition: all .3s ease-in-out .3s;
    opacity: 0;
}

.portfolio_item .portfolio_item_hover .item_info span.project-title {
    font-size: 18px;
}

.portfolio_item .portfolio_item_hover .item_info em {
    font-style: normal;
    display: inline-block;
    background-color: #fff;
    padding: 5px 20px;
    color: #000;
    margin-top: 10px;
    -webkit-transform: translateY(100px);
    transform: translateY(100px);
    -webkit-transition: all .3s ease-in-out .3s;
    transition: all .3s ease-in-out .3s;
    opacity: 0;
    font-size: 10px;
    letter-spacing: 2px;
}

.portfolio_item:hover .portfolio_item_hover {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0);
}

.portfolio_item:hover .item_info em,
.portfolio_item:hover .item_info span {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0);
}

.portfolio .categories-grid .categories ul li {
    float: left;
}

.portfolio .categories-grid .categories ul li a {
    padding: 0 10px;
    -webkit-transition: all .2s ease-in-out .2s;
    transition: all .2s ease-in-out .2s;
}

.portfolio_filter {
    padding-left: 0;
    display: inline-block;
    margin: 0 auto;
    text-align: center;
}

.portfolio-padding {
    padding: 50px 0;
}


.modal-content {
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0);
    border: none;
}

.popup-modal {
    background: #fff;
}

.close-popup-modal {
    float: right;
    margin: 20px;
    font-size: 22px;
    opacity: 0;
    cursor: pointer;
    -webkit-transition: all .3s;
    transition: all .3s;
    -webkit-transition-delay: .6s;
    transition-delay: .6s;
    position: fixed;
    z-index: 100000;
    right: 14px;
}

.animatedModal-on .close-popup-modal {
    opacity: 1;
}

.modal-content h2 {
    font-family: 'Playfair Display', serif;
    font-size: 40px;
    line-height: 50px;
}