/* experience section
==================================================*/

#experience {
    background: #f3f3f3
}

.timeline-wrap {
    width: 75%;
}

.growth-img {
    width: 25%;
}

.experience-row {
    padding: 20px 0;
    border-bottom: 1px solid #ddd;
}

.experience-row h3 {
    font-family: 'Lato', sans-serif;
    text-transform: capitalize;
    letter-spacing: 2px;
    color: #425BB5;
    font-size: 16px;
    font-weight: bold
}

.experience-row p {
    color: #888;
    margin-top: 20px;
    font-size: 15px;
}

.experience-row .date {
    color: #000;
    font-size: 17px;
}

.timeline {
    padding-left: 0;
    list-style: none;
    position: relative;
}

.timeline:before {
    background-color: black;
    content: '';
    margin-left: -1px;
    position: absolute;
    top: 0;
    left: 2em;
    width: 2px;
    height: 100%;
}

.timeline-event {
    position: relative;
}

.timeline-event:hover .timeline-event-icon {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    background-color: #425BB5;
}

.timeline-event:hover .timeline-event-thumbnail {
    box-shadow: inset 40em 0 0 0 #425BB5;
}

.timeline-event-copy {
    padding: 2em;
    position: relative;
    top: -1.875em;
    left: 4em;
    width: 90%;
}

.timeline-event-copy .job-title {
    font-size: 2.4em;
}


.timeline-event-copy strong {
    font-weight: 700;
}

.timeline-event-copy p:not(.timeline-event-thumbnail) {
    font-size: 16px;
    padding-top: 20px;
}

.timeline-event-icon {
    -webkit-transition: -webkit-transform 0.2s ease-in;
    transition: -webkit-transform 0.2s ease-in;
    transition: transform 0.2s ease-in;
    transition: transform 0.2s ease-in, -webkit-transform 0.2s ease-in;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    background-color: #425BB5;
    outline: 10px solid #f3f3f3;
    display: block;
    margin: 0.5em 0.5em 0.5em -0.5em;
    position: absolute;
    top: 0;
    left: 2em;
    width: 1em;
    height: 1em;
}

.timeline-event-thumbnail {
    -moz-transition: box-shadow 0.5s ease-in 0.1s;
    -o-transition: box-shadow 0.5s ease-in 0.1s;
    -webkit-transition: box-shadow 0.5s ease-in;
    -webkit-transition-delay: 0.1s;
    -webkit-transition: box-shadow 0.5s ease-in 0.1s;
    transition: box-shadow 0.5s ease-in 0.1s;
    color: white;
    font-size: 12px;
    font-weight: bold;
    background-color: #555;
    box-shadow: inset 0 0 0 0em #425BB5;
    display: inline-block;
    margin-bottom: 1.2em;
    padding: 0.25em 1em 0.2em 1em;
}